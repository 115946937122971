const single_sided = [
  "Spkr. +",
  "Spkr -",
  "Mic +",
  "Mic - ",
  "Screen",
  "PTT",
  "PTT return",
  "Resistor Pin 1",
  "Resistor Pin 2",
  "Strap Pin 1",
  "Strap Pin 2",
]

const dual_sided = [
  "Spkr. L +",
  "Spkr. L -",
  "Spkr. R +",
  "Spkr. R -",
  "Mic +",
  "Mic - ",
  "Screen",
  "PTT",
  "PTT return",
  "Resistor Pin 1",
  "Resistor Pin 2",
  "Strap Pin 1",
  "Strap Pin 2",
]

const getPlugConfiguration = (style) => {
  switch (style) {
    case "single_sided":
      return single_sided
      break
    case "dual_sided":
      return dual_sided
      break
    default:
      return []
      break
  }
}

export default getPlugConfiguration
