import { createSelector } from "reselect"
import { filterHeadsets, filterPTTs } from "./helpers/FilterHelper"

const getHeadsetsItems = (state) => state.headsets.items
const getHeadsetsFiltering = (state) => state.headsets.filtering

export const selectFilteredHeadsets = createSelector(
  [getHeadsetsItems, getHeadsetsFiltering],
  (headsets, filtering) => {
    const filteredHeadsets = filterHeadsets(headsets)(filtering)

    const updatedHeadsets = headsets.map((headset) => {
      const doesMatchFilter = filteredHeadsets.find(
        (filteredHeadset) => filteredHeadset.id === headset.id,
      )
      if (doesMatchFilter) {
        return { ...headset, disabled: false }
      }

      return { ...headset, disabled: true }
    })

    return updatedHeadsets
  },
)

const getPttItems = (state) => state.ptts.items
const getPttFiltering = (state) => state.ptts.filtering

export const selectFilteredPtts = createSelector(
  [getPttItems, getPttFiltering],
  (ptt, filtering) => {
    const filteredPtts = filterPTTs(ptt)(filtering)

    const upldatedPtts = ptt.map((ptt) => {
      const doesMatchFilter = filteredPtts.find(
        (filteredPtt) => filteredPtt.id === ptt.id,
      )
      if (doesMatchFilter) {
        return { ...ptt, disabled: false }
      }

      return { ...ptt, disabled: true }
    })
    return upldatedPtts
  },
)
