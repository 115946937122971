import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import thumbsupImage from "images/thumbsup.svg"

type Location = {
  search: string
}

type ThankYouPageProps = {
  push: (path: string) => void
  location: Location
}

const ThankYouPage = (props: ThankYouPageProps) => {
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    setTimeout(countDown, 1000)
    setTimeout(() => props.push("/" + props.location.search), 5000)
  }, [])

  const countDown = () => {
    setTimer(timer - 1)
    if (timer > 0) setTimeout(countDown, 1000)
  }

  return (
    <div className="h-100 w-100 circular-bg bg-center flex flex-column justify-evenly items-center">
      <div></div>
      <div className="tc w5">
        <img src={thumbsupImage} className="w4 mb3" />
        <h1 className="sh-new fw5 f3 mb4">Thank you!</h1>
        <div className="light-silver f6">
          Your message has been sent. You will receive a confirmation by email.
        </div>
      </div>
      <div className="f7 light-silver i">
        {" "}
        This message closes in {timer} seconds
      </div>
    </div>
  )
}

const mapStateToProps = (_state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ push }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage)
