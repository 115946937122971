import React, { useEffect, useState } from "react"
import ProgressBar from "../components/ProgressBar"
import {
  fetchPtts,
  selectPtt,
  setPTTFilter,
  fetchTopCables,
  selectTopCable,
} from "../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import { prop, isEmpty, map, pluck } from "ramda"
import { nextStepUrl, previousStepUrl } from "../helpers/StepHelper"
import { filterIncompatiblePtts } from "../helpers/IncompatibilityHelper"
import { filterPTTs } from "../helpers/FilterHelper"
import ProgressFooter from "../components/ProgressFooter"
import Header from "../components/Header"
import {
  PlugTypeSelect,
  MicrophonePreamplifierSelect,
  PortTypeSelect,
} from "./pttSeries/Components"
import { PttListWithDropdown } from "../lists/PttListWithDropdown"
import {
  plugTypeTooltip,
  microphonePreamplifierTooltip,
  portTypeTooltip,
} from "../utils/const"
import { getFooterArray } from "../utils/getFooterArray"
import { getCart } from "../cartSelector"
import { updateStateWithNewId } from "../reducers"

const PttsPage = ({
  fetchPtts,
  fetchTopCables,
  topCables,
  selectPtt,
  selectTopCable,
  ptts,
  push,
  cartIds,
  stepSkippers,
  incompatibilities,
  location,
  cart,
  pttFiltering,
  setPTTFilter,
}: any) => {
  const [statePtts, setStatePtts] = useState<any>([])
  const isTestMode = location.search.includes("test=true")
  const currentPage = "ptts"

  useEffect(() => {
    const pttSeriesId = cartIds.pttSeriesId
    fetchPtts(pttSeriesId)
    fetchTopCables()
  }, [])

  useEffect(() => {
    if (!isEmpty(ptts)) {
      const compatiblePttDevices = compatiblePtts(ptts, [])

      setStatePtts(
        map(toggleItemEnabled(pluck("id", compatiblePttDevices)), ptts),
      )
    }
  }, [ptts])

  const compatiblePtts = (ptts: any, incompatiblePtts: any) =>
    ptts.filter(
      (ptts: any) =>
        !incompatiblePtts.some(
          (incompatiblePtts: any) => incompatiblePtts.id == ptts.id,
        ),
    )

  const disableItem = (item: any) => ({ ...item, disabled: true })
  const enableItem = (item: any) => ({ ...item, disabled: false })

  function toggleItemEnabled(enabledIds: any) {
    return function (item: any) {
      if (enabledIds.includes(item.id)) {
        return enableItem(item)
      } else {
        return disableItem(item)
      }
    }
  }

  // this function will navigate us to the next page without selecting a headset
  const skipCurrentStep = () => {
    push(
      nextStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
  }

  // We pass here the arguments because it has not yet updated in the global state.
  // TODO: Refactor this to use the global state instead of passing it as an argument.
  const onNextStep = (pttId: number, topCableId: number) => {
    updateStateWithNewId(cartIds, "pttId", pttId)
    updateStateWithNewId(cartIds, "topCableId", topCableId)
    
    push(
      nextStepUrl(
        currentPage,
        { ...cartIds, pttId, topCableId },
        stepSkippers,
        isTestMode,
      ) + location.search,
    )
  }

  const onPreviousStep = () => {
    push(
      previousStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
    getFooterArray(cart).slice(0, -1)
  }

  const onPttsSelect = async (pttId: number, topCableId: number) => {
    await selectPtt(pttId)
    await selectTopCable(topCableId)
    onNextStep(pttId, topCableId)
  }

  const onPortTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target

    const port_type = isEmpty(value) ? "" : value
    setPTTFilter({ port_type })
  }

  const onMicrophonePreamplifierChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = e.target
    const microphone_preamplifier = isEmpty(value) ? "" : value == "true"

    setPTTFilter({ microphone_preamplifier })
  }

  const onPlugTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const plug_type = isEmpty(value) ? "" : value

    setPTTFilter({ plug_type })
  }

  const filterCompatiblePTTs = (ptts: any) =>
    ptts.filter(
      (ptt: any) =>
        !filterIncompatiblePtts({ ptts, incompatibilities, cartIds }).some(
          (incompatiblePtt: any) => incompatiblePtt.id == ptt.id,
        ),
    )

  const filterPtts = (options: any) => {
    const compatiblePTTs = isTestMode ? ptts : filterCompatiblePTTs(ptts)

    const filteredPttsIds = filterPTTs(compatiblePTTs)(options).map(
      (ptt: any) => ptt.id,
    )

    const noPTT = statePtts.find((ptt: any) => ptt.name == "No PTT")
    if (noPTT && !noPTT.disabled) {
      filteredPttsIds.push(noPTT.id)
    }

    const result = statePtts.map((item: any) =>
      toggleItemEnabled(filteredPttsIds)(item),
    )

    return result
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
        <ProgressBar
          activeStep={4}
          numberOfSteps={9}
          title="Configurate headset with PTT and top cable"
        />
        <section className="mt-5 lg:mt-10 mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 mb-10">
            <PortTypeSelect
              onChange={onPortTypeChange}
              selected={prop("port_type", pttFiltering)}
              wrapperClass=""
              tooltip={portTypeTooltip}
              className="tooltip-selectPage"
            />
            <MicrophonePreamplifierSelect
              onChange={onMicrophonePreamplifierChange}
              selected={prop("microphone_preamplifier", pttFiltering)}
              wrapperClass=""
              tooltip={microphonePreamplifierTooltip}
              className="tooltip-selectPage"
            />
            <PlugTypeSelect
              onChange={onPlugTypeChange}
              selected={prop("plug_type", pttFiltering)}
              wrapperClass=""
              tooltip={plugTypeTooltip}
              className="tooltip-selectPage"
            />
          </div>
          <PttListWithDropdown
            list={filterPtts(pttFiltering)}
            onSelect={onPttsSelect}
          />
        </section>
      </div>
      <ProgressFooter
        backStep={onPreviousStep}
        skipStep={skipCurrentStep}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    ptts: state.ptts.items,
    cartIds: state.cartIds,
    cart: getCart(state),
    stepSkippers: state.stepSkippers.items,
    incompatibilities: state.incompatibilities.items,
    pttFiltering: state.ptts.filtering,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchPtts,
      selectPtt,
      push,
      fetchTopCables,
      selectTopCable,
      setPTTFilter,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(PttsPage)
