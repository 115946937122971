import React, { Component } from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { range, map, inc } from "ramda"

import ProgressBar from "../components/ProgressBar"
import ProgressFooter from "../components/ProgressFooter"
import Header from "../components/Header"
import TextArea from "../components/TextArea"
import InputField from "../components/InputField"
import Dropdown from "../components/Dropdown"
import { PrimaryButton } from "../components/Buttons"
import { getCart } from "../cartSelector"
import { setPlugConfiguration } from "../actions"
import getPlugConfiguration from "../utils/plug_configuration"
import plugImage from "images/ATC-Generic-Connector.png"
import PropTypes from "prop-types"
import { nextStepUrl, previousStepUrl } from "../helpers/StepHelper"
import { resetCart } from "../actions"

const buildPinObject = (i) => ({ name: `Pin ${i}`, value: `Pin ${i}` })
const number_of_pins = ({ number_of_pins }) => range(1, inc(number_of_pins))

const getPlugConfigurationValues = (plugConfiguration) => (label) => ({
  label: label,
  value: plugConfiguration[label],
})

class PlugConfigurationPage extends Component {
  constructor(props) {
    super(props)
    this.onPreviousStep = this.onPreviousStep.bind(this)
    this.onContinue = this.onContinue.bind(this)
    this.onRestart = this.onRestart.bind(this)
    const { plug } = this.props
    const pinOptions = map(buildPinObject, number_of_pins(plug))

    this.state = {
      pinOptions,
      configuration: props.plugConfiguration,
    }
  }

  onPreviousStep() {
    this.props.push(
      previousStepUrl(
        "plugConfiguration",
        this.props.cartIds,
        this.props.stepSkippers,
        this.state.isTestMode,
      ) + this.props.location.search,
    )
  }

  onContinue() {
    this.props.setPlugConfiguration(this.state.configuration)
    this.props.push(
      nextStepUrl(
        "plugConfiguration",
        this.props.cartIds,
        this.props.stepSkippers,
        this.state.isTestMode,
      ) + this.props.location.search,
    )
  }

  onRestart() {
    this.props.resetCart()
    this.props.push("headsetSeries" + this.props.location.search)
  }

  updateConfiguration(label, value) {
    const configuration = { ...this.state.configuration, [label]: value }
    this.setState({ configuration })
  }

  onPinSelected = ({ label, value }) => {
    let pinOptions = this.state.pinOptions
    this.updateConfiguration(label, value)
    this.setState({ pinOptions })
  }

  onInputValueChange = (key) => (e) => {
    this.updateConfiguration(key, e.target.value)
  }

  render() {
    const { wearing_style } = this.props.headset
    const defaultPlugConfiguration = map(
      getPlugConfigurationValues(this.props.plugConfiguration),
      getPlugConfiguration(wearing_style),
    )

    return (
      <div>
        <Header />
        <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
          <ProgressBar
            title="Configurate connector"
            activeStep={9}
            numberOfSteps={9}
          />
          <section className="flex flex-col gap-10 lg:flex-row justify-between mt-5 lg:mt-10 mb-32 lg:gap-5 xl:gap-10 2xl:gap-20">
            <div className="lg:w-1/3">
              <div className="border border-solid border-slate-200">
                <img className="py-12" src={plugImage} />
              </div>
            </div>
            <div className="lg:w-2/3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {this.state.pinOptions.map((option, index) => (
                  <Dropdown
                    key={index}
                    label={option.name}
                    onChange={this.onPinSelected}
                    options={defaultPlugConfiguration}
                    value={this.state.configuration[option.value]}
                  />
                ))}
              </div>

              <div className="grid grid-cols-2 gap-4 mt-8 mb-4">
                <InputField
                  label="Resistor value or chip"
                  placeholder="1 - 1.000.000 Ohm"
                  onChange={this.onInputValueChange("resistorValue")}
                  value={this.state.configuration.resistorValue}
                />
              </div>

              <TextArea
                label="Comments or additional requirements"
                onChange={this.onInputValueChange("additionalRequirements")}
                value={this.state.configuration.additionalRequirements}
              />
              <PrimaryButton
                onClick={this.onContinue}
                className="mv4 py-5 px-12 text-lg"
                text="Save configuration and continue"
              />
            </div>
          </section>
        </div>
        <ProgressFooter
          backStep={this.onPreviousStep}
          skipStepText="Restart configurator"
          skipStep={this.onRestart}
        />
      </div>
    )
  }
}

PlugConfigurationPage.propTypes = {
  setPlugConfiguration: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  cartIds: PropTypes.object,
  resetCart: PropTypes.func.isRequired,
  stepSkippers: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    headset: getCart(state).headset,
    plug: getCart(state).plug,
    plugConfiguration: state.plugConfiguration,
    cart: getCart(state),
    cartIds: state.cartIds,
    stepSkippers: state.stepSkippers.items,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, resetCart, setPlugConfiguration }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlugConfigurationPage)
