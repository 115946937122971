import React, { useEffect } from "react"
import { nextStepUrl, previousStepUrl } from "../helpers/StepHelper"
import ProgressFooter from "../components/ProgressFooter"
import { getFooterArray } from "../utils/getFooterArray"
import { getCart } from "../cartSelector"
import Header from "../components/Header"
import ProgressBar from "../components/ProgressBar"
import { bindActionCreators } from "redux"
import { fetchHeadsets, selectHeadset, setHeadsetFilter } from "../actions"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { ColumnList } from "../lists/ColumnList"
import {
  activegardTooltip,
  headsetTooltip,
  microphoneTooltip,
} from "../utils/const"
import { prop, isEmpty } from "ramda"
import {
  ActiveGardSelect,
  MicrophoneTypeSelect,
  WearingStyleSelect,
} from "./headsetSeries/Components"
import { selectFilteredHeadsets } from "../selectors"
import { updateStateWithNewId } from "../reducers"

const HeadsetsPage = ({
  fetchHeadsets,
  selectHeadset,
  push,
  cartIds,
  stepSkippers,
  filteredHeadsets,
  location,
  cart,
  headsetFiltering,
  setHeadsetFilter,
}: any) => {
  const isTestMode = location.search.includes("test=true")
  const currentPage = "headsets"

  useEffect(() => {
    const headsetSeriesId = cartIds.headsetSeriesId
    fetchHeadsets(headsetSeriesId)
  }, [])

  // this function will navigate us to the next page without selecting a headset
  const skipCurrentStep = () => {
    push(
      nextStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
  }

  // We pass here the arguments because it has not yet updated in the global state.
  // TODO: Refactor this to use the global state instead of passing it as an argument.
  const onNextStep = (headsetId: number) => {

    push(
      nextStepUrl(
        currentPage,
        updateStateWithNewId(cartIds, 'headsetId', headsetId),
        stepSkippers,
        isTestMode,
      ) + location.search,
    )
  }

  const onPreviousStep = () => {
    push(
      previousStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
    getFooterArray(cart).slice(0, -1)
  }

  const onHeadsetSelect = async (headsetId: number) => {
    await selectHeadset(headsetId)

    onNextStep(headsetId)
  }

  const onMicrophoneTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const microphone_type = isEmpty(value) ? "" : value

    setHeadsetFilter({ microphone_type })
  }

  const onActiveGardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const active_gard = isEmpty(value) ? "" : value

    setHeadsetFilter({ active_gard })
  }

  const onWearingStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const wearing_style = isEmpty(value) ? "" : value

    setHeadsetFilter({ wearing_style })
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
        <ProgressBar
          activeStep={2}
          numberOfSteps={9}
          title="Choose headset variant"
        />
        <section className="mt-5 lg:mt-10 mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 mb-10">
            <MicrophoneTypeSelect
              onChange={onMicrophoneTypeChange}
              selected={prop("microphone_type", headsetFiltering)}
              wrapperClass=""
              tooltip={microphoneTooltip}
              className="tooltip-selectPage"
            />
            <WearingStyleSelect
              onChange={onWearingStyleChange}
              selected={prop("wearing_style", headsetFiltering)}
              wrapperClass=""
              tooltip={headsetTooltip}
              className="tooltip-selectPage"
            />
            <ActiveGardSelect
              onChange={onActiveGardChange}
              selected={prop("active_gard", headsetFiltering)}
              wrapperClass=""
              tooltip={activegardTooltip}
              className="tooltip-selectPage"
            />
          </div>
          <ColumnList list={filteredHeadsets} onSelect={onHeadsetSelect} />
        </section>
      </div>
      <ProgressFooter
        backStep={onPreviousStep}
        skipStep={skipCurrentStep}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    headsets: state.headsets.items,
    cartIds: state.cartIds,
    cart: getCart(state),
    filteredHeadsets: selectFilteredHeadsets(state),
    stepSkippers: state.stepSkippers.items,
    incompatibilities: state.incompatibilities.items,
    headsetFiltering: state.headsets.filtering,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchHeadsets, selectHeadset, push, setHeadsetFilter },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(HeadsetsPage)
