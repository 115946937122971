import React from "react"

import { PttSeriesWithSettingsType, PttType } from "../utils/types"
import PttCardWithDropdown from "../components/PttCardWithDropdown"

type Props = {
  list: PttType[] | PttSeriesWithSettingsType[]
  onSelect: (pttId: number, topCableId: number) => Promise<void>
  series?: boolean
}

export const PttListWithDropdown = (props: Props) => {
  const { list, onSelect, series } = props

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
      {list.map((element) =>
        element.disabled ? (
          <PttCardWithDropdown
            key={element.id}
            {...element}
            series={series}
            className="opacity-25 cursor-not-allowed"
            addOnName="top cable"
          />
        ) : (
          <PttCardWithDropdown
            key={element.id}
            onSelect={onSelect}
            {...element}
            series={series}
            addOnName="top cable"
          />
        ),
      )}
    </div>
  )
}
