import React from "react"
import Tooltip from "../../components/Tooltip"

export const PortTypeSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}) => (
  <div className={wrapperClass}>
    <div className="flex items-center">
      <h3 className="f6 f4-ns mv2 mv3-ns">Interface</h3>
      <Tooltip text={tooltip} className={className} />
    </div>
    <select
      className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
      onChange={onChange}
      value={selected}
    >
      <option value="">Choose variant</option>
      <option value="analogue">Analogue</option>
      <option value="usb">USB</option>
    </select>
  </div>
)

export const MicrophonePreamplifierSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}) => (
  <div className={wrapperClass}>
    <div className="flex items-center">
      <h3 className="f6 f4-ns mv2 mv3-ns">Preamplifier</h3>
      <Tooltip text={tooltip} className={className} />
    </div>
    <select
      className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
      onChange={onChange}
      value={selected}
    >
      <option value="">Choose variant</option>
      <option value="true">With Preamplifier</option>
      <option value="false">Without Preamplifier</option>
    </select>
  </div>
)

export const PlugTypeSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}) => (
  <div className={wrapperClass}>
    <div className="flex items-center">
      <h3 className="f6 f4-ns mv2 mv3-ns">Connector type</h3>
      <Tooltip text={tooltip} className={className} />
    </div>
    <select
      className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
      onChange={onChange}
      value={selected}
    >
      <option value="">Choose variant</option>
      <option value="direct">Direct</option>
      <option value="ta6">TA6</option>
    </select>
  </div>
)
