import React, { Component } from "react"
import PropTypes from "prop-types"
import ProgressBar from "../components/ProgressBar"
import Header from "../components/Header"
import OrderDetails from "../components/OrderDetails"
import OrderDetailsVisual from "../components/OrderDetailsVisual"
import { PrimaryButton, SecondaryButton } from "../components/Buttons"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getCart } from "../cartSelector"
import { previousStepUrl } from "../helpers/StepHelper"
import CustomerDetailsModal from "../components/CustomerDetailsModal"
import { map, values, isNil, reject } from "ramda"
import { resetCart, submitInquiry } from "../actions"
import StepHeader from "../components/StepHeader"
import ProgressFooter from "../components/ProgressFooter"

const toGroupListItem = (item) => ({
  title: item.title,
  subtitle: item.name,
  content: item.description,
  ...item,
})

const toGroupList = (items) => map(toGroupListItem, values(items))
const emptyItems = (item) => isNil(item.content)

class YourHeadsetPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      cartItems: toGroupList(this.props.cart),
      isTestMode: props.location.search.includes("test=true"),
    }
    this.onPreviousStep = this.onPreviousStep.bind(this)
    this.cartArray = this.cartArray.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onModalSubmit = this.onModalSubmit.bind(this)
    this.inquiryBody = this.inquiryBody.bind(this)
  }

  onPreviousStep() {
    this.props.push(
      previousStepUrl(
        "yourHeadset",
        this.props.cartIds,
        this.props.stepSkippers,
        this.state.isTestMode,
      ) + this.props.location.search,
    )
  }

  cartArray = () => reject(emptyItems, this.state.cartItems)

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  onModalSubmit(e) {
    this.setState({ isModalOpen: false })
    this.props.submitInquiry(this.inquiryBody())
    this.props.resetCart()
    this.props.push("/thankYou" + this.props.location.search)
  }

  inquiryBody() {
    const { cart, cartIds, modalForm } = this.props
    return {
      headset_id: cartIds.headsetId,
      top_cable_id: cartIds.topCableId,
      ptt_id: cartIds.pttId,
      bottom_cable_id: cartIds.bottomCableId,
      plug_id: cartIds.plugId,
      custom_plug_specification: cartIds.customPlugSpecification,
      cable_without_ptt_id: cartIds.cableWithoutPttId,
      name: modalForm.name,
      company_name: modalForm.companyName,
      address: modalForm.address,
      zip_code: modalForm.zipCode,
      city: modalForm.city,
      phone_number: modalForm.phoneNumber,
      email: modalForm.email,
      total_amount: modalForm.totalAmount,
      comments: modalForm.comments,
      country_alpha3: modalForm.country,
      plug_configuration: cart.plugConfiguration.htmlDescription,
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
          <ProgressBar activeStep={9} numberOfSteps={9} title="Finished" />
          <StepHeader text="Your configuration is done" />
          <section className="flex flex-col gap-10 lg:flex-row justify-between mt-5 lg:mt-10 mb-32 lg:gap-5 xl:gap-10 2xl:gap-20">
            <div className="lg:w-1/2">
              <OrderDetailsVisual order={this.cartArray()} />
            </div>
            <div className="lg:w-1/2">
              <div className="mb-6 text-lg">
                Your setup is complete. Below, you will find all the necessary
                information. Additionally, you have the option to print this
                information as a PDF or submit an inquiry for further
                assistance.
              </div>
              <OrderDetails order={this.cartArray()} />
              <div className="mt-4 flex flex-col gap-5 w-full sm:flex-row">
                <PrimaryButton
                  onClick={this.toggleModal}
                  className="no-print py-5 px-6 xl:px-10 text-lg"
                  text="Make Inquiry"
                />
                <SecondaryButton
                  onClick={() => window.print()}
                  className="no-print py-5 px-6 xl:px-10 text-lg"
                  text="Print configuration"
                />
              </div>
            </div>
          </section>
          <ProgressFooter backStep={this.onPreviousStep} />
        </div>
        <CustomerDetailsModal
          show={this.state.isModalOpen}
          onModalSubmit={this.onModalSubmit}
          onClose={this.toggleModal}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const customerDetails = state.customerDetails
  return {
    cart: getCart(state),
    cartIds: state.cartIds,
    stepSkippers: state.stepSkippers.items,
    modalForm: customerDetails,
  }
}

YourHeadsetPage.propTypes = {
  push: PropTypes.func.isRequired,
  submitInquiry: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  cartIds: PropTypes.object,
  stepSkippers: PropTypes.array,
  modalForm: PropTypes.object,
  resetCart: PropTypes.func.isRequired,
  location: PropTypes.object,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, resetCart, submitInquiry }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(YourHeadsetPage)
