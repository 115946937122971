import { isBlank } from "../utils"
import { isEmpty } from "ramda"

const sameActiveGard = (headset, active_gard) =>
  isEmpty(active_gard) ||
  headset.active_gard == "both" ||
  headset.active_gard == active_gard

export const filterHeadsets = (headsets) => (options) => {
  const wearingStyleHeadsetsFilter =
    ({ wearing_style }) =>
    (headset) =>
      isBlank(wearing_style) ? true : headset.wearing_style == wearing_style
  const activeGardHeadsetsFilter =
    ({ active_gard }) =>
    (headset) =>
      isBlank(active_gard) ? true : sameActiveGard(headset, active_gard)
  const microphoneTypeHeadsetsFilter =
    ({ microphone_type }) =>
    (headset) =>
      isBlank(microphone_type)
        ? true
        : headset.microphone_type == microphone_type

  return headsets
    .filter(microphoneTypeHeadsetsFilter(options))
    .filter(wearingStyleHeadsetsFilter(options))
    .filter(activeGardHeadsetsFilter(options))
}

export const filterPTTs = (ptts) => (options) => {
  const portTypeFilter =
    ({ port_type }) =>
    (ptt) =>
      isBlank(port_type) ? true : ptt.port_type == port_type
  const plugTypeFilter =
    ({ plug_type }) =>
    (ptt) =>
      isBlank(plug_type) ? true : ptt.plug_type == plug_type
  const microphonePreamplifierFilter =
    ({ microphone_preamplifier }) =>
    (ptt) =>
      isBlank(microphone_preamplifier)
        ? true
        : ptt.microphone_preamplifier == microphone_preamplifier

  return ptts
    .filter(portTypeFilter(options))
    .filter(microphonePreamplifierFilter(options))
    .filter(plugTypeFilter(options))
}
