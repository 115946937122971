import React, { useEffect, useState } from "react"
import ProgressBar from "../components/ProgressBar"
import {
  fetchBottomCables,
  fetchCableWithoutPtts,
  fetchPlugs,
  selectBottomCable,
  selectCableWithoutPtt,
  selectPlug,
} from "../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import { nextStepUrl, previousStepUrl } from "../helpers/StepHelper"
import { filterIncompatibleCableWithoutPtts } from "../helpers/IncompatibilityHelper"
import Header from "../components/Header"
import ProgressFooter from "../components/ProgressFooter"
import useFilteredCables from "../hooks/useFilteredCables"
import CableCardWithDropdown from "../components/CableCardWithDropdown"
import { getCart } from "../cartSelector"
import { updateStateWithNewId } from "../reducers"

type Cable = {
  id: number
  name: string
  image_url: string
  description: string
}

const CableWithoutPttsPage = ({
  bottomCables,
  cableWithoutPtts,
  cartIds,
  stepSkippers,
  incompatibilities,
  fetchBottomCables,
  fetchPlugs,
  fetchCableWithoutPtts,
  selectCableWithoutPtt,
  selectPlug,
  push,
  cart,
}: any) => {
  const isTestMode = location.search.includes("test=true")
  const currentPage = "cableWithoutPtts"
  const [activeCables, setActiveCables] = useState(bottomCables)
  const { compatibleCables: compatibleCableWithoutPtts } = useFilteredCables(
    cableWithoutPtts,
    filterIncompatibleCableWithoutPtts,
    incompatibilities,
    cartIds,
  )

  useEffect(() => {
    if (activeCables.length === 0 && bottomCables.length > 0)
      setActiveCables(bottomCables)
  }, [bottomCables, cableWithoutPtts])

  useEffect(() => {
    fetchBottomCables()
    fetchCableWithoutPtts()
    fetchPlugs()
  }, [])

  useEffect(() => {
    setActiveCables(compatibleCableWithoutPtts)
  }, [activeCables])

  const onPreviousStep = () => {
    push(
      previousStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
  }

  // this function will navigate us to the next page without selecting a headset
  const skipCurrentStep = () => {
    push(
      nextStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
  }

  // We pass here the arguments because it has not yet updated in the global state.
  // TODO: Refactor this to use the global state instead of passing it as an argument.
  const onNextStep = (cableWithoutPttId: number, plugId: number) => {
    updateStateWithNewId(cartIds, "cableWithoutPttId", cableWithoutPttId)
    updateStateWithNewId(cartIds, "plugId", plugId)
    push(
      nextStepUrl(
        currentPage,
        { ...cartIds, cableWithoutPttId, plugId },
        stepSkippers,
        isTestMode,
      ) + location.search,
    )
  }

  const onCableSelect = async (cableId: number, plugId: number) => {
    await selectCableWithoutPtt(cableId)
    await selectPlug(plugId)
    onNextStep(cableId, plugId)
  }

  return (
    <div className="font-sans">
      <Header />
      <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
        <ProgressBar
          title="Configurate headset with cables without PTTs"
          activeStep={6}
          numberOfSteps={9}
        />
        <section className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5 mt-5 lg:mt-20 mb-20">
          {activeCables.length > 0 ? (
            <>
              {activeCables.map((cable: Cable) => (
                <CableCardWithDropdown
                  key={cable.id}
                  onSelect={onCableSelect}
                  {...cable}
                  addOnName="connector"
                  cableIdName="cableWithoutPttId"
                />
              ))}
            </>
          ) : (
            <div>No cables found</div>
          )}
        </section>
      </div>

      <ProgressFooter backStep={onPreviousStep} skipStep={skipCurrentStep} />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    bottomCables: state.bottomCables.items,
    cableWithoutPtts: state.cableWithoutPtts.items,
    plugs: state.plugs.items,
    cartIds: state.cartIds,
    stepSkippers: state.stepSkippers.items,
    incompatibilities: state.incompatibilities.items,
    cart: getCart(state),
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchBottomCables,
      fetchPlugs,
      fetchCableWithoutPtts,
      selectBottomCable,
      selectCableWithoutPtt,
      selectPlug,
      push,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CableWithoutPttsPage)
