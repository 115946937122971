import { useMemo } from "react"

const useFilteredCables = (
  cables: any,
  filterFunction: any,
  incompatibilities: any,
  cartIds: any,
) => {
  const incompatibleCables = useMemo(
    () => filterFunction(cables, incompatibilities, cartIds),
    [cables, incompatibilities, cartIds],
  )

  const compatibleCables = useMemo(
    () =>
      cables.filter(
        (cable: { id: number }) =>
          !incompatibleCables.some(
            (incompatCable: any) => incompatCable.id === cable.id,
          ),
      ),
    [cables, incompatibleCables],
  )

  return { compatibleCables }
}

export default useFilteredCables
