import React from "react"
import Tooltip from '../../components/Tooltip'

type Props = {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  selected: string
  tooltip: string
  wrapperClass: string
  className: string
}

export const ActiveGardSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}: Props) => {
  return (
    <div className={wrapperClass}>
      <div className="flex items-center">
        <h3 className="f6 f4-ns mv2 mv3-ns">ActiveGard™</h3>
        <Tooltip text={tooltip} className={className} />
      </div>
      <select
        className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
        onChange={onChange}
        value={selected}
      >
        <option value="">Choose variant</option>
        <option value="only_with">With ActiveGard™</option>
        <option value="only_without">Without ActiveGard™</option>
      </select>
    </div>
  )
}

export const WearingStyleSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}: Props) => {
  return (
    <div className={wrapperClass}>
      <div className="flex items-center">
        <h3 className="f6 f4-ns mv2 mv3-ns">Headset</h3>
        <Tooltip text={tooltip} className={className} />
      </div>
      <select
        className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
        onChange={onChange}
        value={selected}
      >
        <option value="">Choose variant</option>
        <option value="single_sided">Single sided</option>
        <option value="dual_sided">Dual sided</option>
      </select>
    </div>
  )
}

export const MicrophoneTypeSelect = ({
  onChange,
  selected,
  tooltip,
  wrapperClass = "",
  className,
}: Props) => {
  return (
    <div className={wrapperClass}>
      <div className="flex items-center">
        <h3 className="f6 f4-ns mv2 mv3-ns">Microphone</h3>
        <Tooltip text={tooltip} className={className} />
      </div>
      <select
        className="gray bg-white w-100 py-4 px-5 br1 input-reset select-bg"
        onChange={onChange}
        value={selected}
      >
        <option value="">Choose variant</option>
        <option value="electret">Electret</option>
        <option value="dynamic">Dynamic</option>
      </select>
    </div>
  )
}
