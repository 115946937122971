import React from "react"
import { camelCase } from "change-case"

// On test mode, we don't skip any step
export const nextStepUrl = (
  currentUrl,
  cartIds,
  stepSkippers,
  testMode = false,
) => {
  let step = currentUrl
  do {
    step = nextStep(step)
  } while (skipStep(step, cartIds, stepSkippers) && !testMode)

  return "/" + step
}

export const previousStepUrl = (
  currentUrl,
  cartIds,
  stepSkippers,
  testMode = false,
) => {
  let step = currentUrl
  do {
    step = previousStep(step)
  } while (skipStep(step, cartIds, stepSkippers) && !testMode)

  return "/" + step
}

const skipStep = (step, cartIds, stepSkippers) => {
  return (
    anyStepSkipperApplies(step, cartIds, stepSkippers) ||
    (step === "plugConfiguration" && !hasPlug(cartIds))
  )
}

const hasPlug = (cartIds) => {
  return Number.isInteger(cartIds.plugId) && cartIds.plugId > 0
}

const anyStepSkipperApplies = (step, cartIds, stepSkippers) =>
  stepSkippers.some((stepSkipper) => {
    return (
      step == camelCase(stepSkipper.step) &&
      (!stepSkipper.bottom_cable_id ||
        stepSkipper.bottom_cable_id == cartIds.bottomCableId) &&
      (!stepSkipper.headset_series_id ||
        stepSkipper.headset_series_id == cartIds.headsetSeriesId) &&
      (!stepSkipper.headset_id ||
        stepSkipper.headset_id == cartIds.headsetId) &&
      (!stepSkipper.ptt_series_id ||
        stepSkipper.ptt_series_id == cartIds.pttSeriesId) &&
      (!stepSkipper.ptt_id || stepSkipper.ptt_id == cartIds.pttId) &&
      (!stepSkipper.top_cable_id ||
        stepSkipper.top_cable_id == cartIds.topCableId)
    )
  })

const nextStepIndex = (currentStepIndex, STEPS) =>
  currentStepIndex < STEPS.length ? currentStepIndex + 1 : currentStepIndex
const previousStepIndex = (currentStepIndex, STEPS) =>
  currentStepIndex > 0 ? currentStepIndex - 1 : 0

const currentStepIndex = (currentStep) => STEPS.indexOf(currentStep)

const nextStep = (currentStep) =>
  STEPS[nextStepIndex(currentStepIndex(currentStep), STEPS)]
const previousStep = (currentStep) =>
  STEPS[previousStepIndex(currentStepIndex(currentStep), STEPS)]

const STEPS = [
  "headsetSeries",
  "headsets",
  "pttSeries",
  "ptts",
  "bottomCables",
  "cableWithoutPtts",
  "plugConfiguration",
  "yourHeadset",
]
