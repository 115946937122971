import {
  REQUEST_HEADSET_SERIES,
  RECEIVE_HEADSET_SERIES,
  SELECT_HEADSET_SERIES,
  REQUEST_HEADSETS,
  RECEIVE_HEADSETS,
  SELECT_HEADSET,
  REQUEST_TOP_CABLES,
  RECEIVE_TOP_CABLES,
  SELECT_TOP_CABLE,
  REQUEST_PTT_SERIES,
  RECEIVE_PTT_SERIES,
  SELECT_PTT_SERIES,
  REQUEST_PTTS,
  RECEIVE_PTTS,
  SELECT_PTT,
  REQUEST_BOTTOM_CABLES,
  RECEIVE_BOTTOM_CABLES,
  SELECT_BOTTOM_CABLE,
  REQUEST_CABLE_WITHOUT_PTTS,
  RECEIVE_CABLE_WITHOUT_PTTS,
  SELECT_CABLE_WITHOUT_PTT,
  REQUEST_PLUGS,
  RECEIVE_PLUGS,
  SELECT_PLUG,
  REQUEST_STEP_SKIPPERS,
  RECEIVE_STEP_SKIPPERS,
  REQUEST_INCOMPATIBILITIES,
  RECEIVE_INCOMPATIBILITIES,
  SET_PLUG_CONFIGURATION,
  RESET_PLUG_CONFIGURATION,
  RESET_CART,
  SET_HEADSET_FILTER,
  SET_PTT_FILTER,
  UPDATE_CUSTOMER_DETAILS,
  RESET_CUSTOMER_DETAILS,
} from "./actions"

const INITIAL_HEADSET_SERIES_STATE = {
  isFetching: false,
  items: [],
}

export function headsetSeries(state = INITIAL_HEADSET_SERIES_STATE, action) {
  switch (action.type) {
    case REQUEST_HEADSET_SERIES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_HEADSET_SERIES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_CART_STATE = {
  headsetSeries: {},
  headset: {},
  topCable: {},
  pttSeries: {},
  ptt: {},
  bottomCable: {},
  plug: {},
  plugConfiguration: {},
  stepSkippers: {},
  incompatibilities: {},
}

const keyHierarchy = [
  "headsetSeriesId",
  "headsetId",
  "pttSeriesId",
  "pttId",
  "topCableId",
  "cableWithoutPttId",
  "bottomCableId",
  "plugId",
  "plugConfigurationId",
]

export const updateStateWithNewId = (state, key, id) => {
  const relevantKeyIndex = keyHierarchy.indexOf(key)
  const laterKeys = keyHierarchy.slice(relevantKeyIndex + 1)

  const newState = { ...state }
  newState[key] = id

  for (const laterKey of laterKeys) {
    newState[laterKey] = undefined
  }

  return newState
}

export function cartIds(state = INITIAL_CART_STATE, action) {
  switch (action.type) {
    case SELECT_HEADSET_SERIES:
      return updateStateWithNewId(
        state,
        "headsetSeriesId",
        action.selectedHeadsetSeriesId,
      )

    case SELECT_HEADSET:
      return updateStateWithNewId(state, "headsetId", action.selectedHeadsetId)

    case SELECT_PTT_SERIES:
      return updateStateWithNewId(
        state,
        "pttSeriesId",
        action.selectedPttSeriesId,
      )

    case SELECT_PTT:
      return updateStateWithNewId(state, "pttId", action.selectedPttId)

    case SELECT_TOP_CABLE:
      return updateStateWithNewId(
        state,
        "topCableId",
        action.selectedTopCableId,
      )

    case SELECT_PLUG:
      return updateStateWithNewId(state, "plugId", action.selectedPlugId)

    case SELECT_BOTTOM_CABLE:
      return updateStateWithNewId(
        state,
        "bottomCableId",
        action.selectedBottomCableId,
      )

    case SELECT_CABLE_WITHOUT_PTT:
      return updateStateWithNewId(
        state,
        "cableWithoutPttId",
        action.selectedCableWithoutPttId,
      )

    case RESET_CART:
      return INITIAL_CART_STATE

    default:
      return state
  }
}

const INITIAL_HEADSETS_STATE = {
  isFetching: false,
  items: [],
  filtering: {},
}

export function headsets(state = INITIAL_HEADSETS_STATE, action) {
  switch (action.type) {
    case REQUEST_HEADSETS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_HEADSETS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case SET_HEADSET_FILTER:
      return {
        ...state,
        filtering: { ...state.filtering, ...action.filtering },
      }
    case RESET_CART:
      return {
        ...state,
        filtering: {},
      }
    default:
      return state
  }
}

const INITIAL_TOP_CABLE_STATE = {
  isFetching: false,
  items: [],
}

export function topCables(state = INITIAL_TOP_CABLE_STATE, action) {
  switch (action.type) {
    case REQUEST_TOP_CABLES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_TOP_CABLES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_PTT_SERIES_STATE = {
  isFetching: false,
  items: [],
}

export function pttSeries(state = INITIAL_PTT_SERIES_STATE, action) {
  switch (action.type) {
    case REQUEST_PTT_SERIES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PTT_SERIES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_PTTS_STATE = {
  isFetching: false,
  items: [],
  filtering: {},
}

export function ptts(state = INITIAL_PTTS_STATE, action) {
  switch (action.type) {
    case REQUEST_PTTS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PTTS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case SET_PTT_FILTER:
      return {
        ...state,
        filtering: { ...state.filtering, ...action.filter },
      }
    case RESET_CART:
      return {
        ...state,
        filtering: {},
      }
    default:
      return state
  }
}

const INITIAL_BOTTOM_CABLES_STATE = {
  isFetching: false,
  items: [],
}

export function bottomCables(state = INITIAL_BOTTOM_CABLES_STATE, action) {
  switch (action.type) {
    case REQUEST_BOTTOM_CABLES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_BOTTOM_CABLES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_CABLE_WITHOUT_PTTS_STATE = {
  isFetching: false,
  items: [],
}

export function cableWithoutPtts(
  state = INITIAL_CABLE_WITHOUT_PTTS_STATE,
  action,
) {
  switch (action.type) {
    case REQUEST_CABLE_WITHOUT_PTTS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_CABLE_WITHOUT_PTTS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_PLUGS_STATE = {
  isFetching: false,
  items: [],
}

export function plugs(state = INITIAL_PLUGS_STATE, action) {
  switch (action.type) {
    case REQUEST_PLUGS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PLUGS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_STEP_SKIPPERS_STATE = {
  isFetching: false,
  items: [],
}

export function stepSkippers(state = INITIAL_STEP_SKIPPERS_STATE, action) {
  switch (action.type) {
    case REQUEST_STEP_SKIPPERS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_STEP_SKIPPERS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const INITIAL_INCOMPATIBILITIES_STATE = {
  isFetching: false,
  items: [],
}

export function incompatibilities(
  state = INITIAL_INCOMPATIBILITIES_STATE,
  action,
) {
  switch (action.type) {
    case REQUEST_INCOMPATIBILITIES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_INCOMPATIBILITIES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

const initialPlugConfigurationState = {}
export function plugConfiguration(
  state = initialPlugConfigurationState,
  action,
) {
  switch (action.type) {
    case SET_PLUG_CONFIGURATION:
      return {
        ...action.payload,
      }
    case SELECT_HEADSET_SERIES:
      return initialPlugConfigurationState
    case SELECT_HEADSET:
      return initialPlugConfigurationState
    case SELECT_PTT_SERIES:
      return initialPlugConfigurationState
    case SELECT_PTT:
      return initialPlugConfigurationState
    case SELECT_TOP_CABLE:
      return initialPlugConfigurationState
    case SELECT_PLUG:
      return initialPlugConfigurationState
    case SELECT_BOTTOM_CABLE:
      return initialPlugConfigurationState
    case SELECT_CABLE_WITHOUT_PTT:
    case RESET_CART:
      return initialPlugConfigurationState
    case RESET_PLUG_CONFIGURATION:
      return initialPlugConfigurationState
    default:
      return state
  }
}

const INITIAL_CUSTOMER_DETAILS_STATE = {
  name: "",
  companyName: "",
  address: "",
  zipCode: "",
  city: "",
  country: "",
  phoneNumber: "",
  email: "",
  totalAmount: "",
  comments: "",
}

export function customerDetails(
  state = INITIAL_CUSTOMER_DETAILS_STATE,
  action,
) {
  switch (action.type) {
    case UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_CUSTOMER_DETAILS:
      return INITIAL_CUSTOMER_DETAILS_STATE
    default:
      return state
  }
}
