import React, { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import Header from "../components/Header"
import ProgressBar from "../components/ProgressBar"
import ProgressFooter from "../components/ProgressFooter"
import { PttList } from "../lists/PttList"
import { getCart } from "../cartSelector"
import { getFooterArray } from "../utils/getFooterArray"
import {
  fetchPttSeries,
  fetchPtts,
  selectPttSeries,
  setPTTFilter,
} from "../actions"
import { nextStepUrl, previousStepUrl } from "../helpers/StepHelper"
import {
  getIncompatiblePttSeries,
  filterIncompatiblePtts,
} from "../helpers/IncompatibilityHelper"
import { filterPTTs } from "../helpers/FilterHelper"
import {
  PlugTypeSelect,
  MicrophonePreamplifierSelect,
  PortTypeSelect,
} from "./pttSeries/Components"
import { map, isEmpty, prop, pluck } from "ramda"
import {
  portTypeTooltip,
  microphonePreamplifierTooltip,
  plugTypeTooltip,
} from "../utils/const"
import { updateStateWithNewId } from "../reducers"


const PttSeriesPage = ({
  fetchPttSeries,
  fetchPtts,
  selectPttSeries,
  stepSkippers,
  push,
  setPTTFilter,
  cartIds,
  pttSeries,
  ptts,
  cart,
  incompatibilities,
  pttFiltering,
}: any) => {
  const [statePttSeries, setStatePttSeries] = useState<any>([])
  const isTestMode = location.search.includes("test=true")
   const currentPage = "pttSeries"

  useEffect(() => {
    fetchPttSeries()
    fetchPtts()
  }, [])

  useEffect(() => {
    if (!isEmpty(pttSeries)) {
      const incompatiblePttSeries = isTestMode
        ? []
        : map(
            disableItem,
            getIncompatiblePttSeries({ pttSeries, incompatibilities, cartIds }),
          )

      const compatibleSeries = compatiblePttSeries(
        pttSeries,
        incompatiblePttSeries,
      )

      setStatePttSeries(
        map(toggleItemEnabled(pluck("id", compatibleSeries)), pttSeries),
      )
    }
  }, [pttSeries])

  const compatiblePttSeries = (pttSeries: any, incompatiblePttSeries: any) =>
    pttSeries.filter(
      (pttSeries: any) =>
        !incompatiblePttSeries.some(
          (incompatiblePttSeries: any) =>
            incompatiblePttSeries.id == pttSeries.id,
        ),
    )

  const disableItem = (item: any) => ({ ...item, disabled: true })
  const enableItem = (item: any) => ({ ...item, disabled: false })

  function toggleItemEnabled(enabledIds: any) {
    return function (item: any) {
      if (enabledIds.includes(item.id)) {
        return enableItem(item)
      } else {
        return disableItem(item)
      }
    }
  }

  const filterCompatiblePTTs = (ptts: any) =>
    ptts.filter(
      (ptt: any) =>
        !filterIncompatiblePtts({ ptts, incompatibilities, cartIds }).some(
          (incompatiblePtt: any) => incompatiblePtt.id == ptt.id,
        ),
    )

  const filterPttSeries = (options: any) => {
    const compatiblePTTs = isTestMode ? ptts : filterCompatiblePTTs(ptts)

    const filteredSeriesIds = filterPTTs(compatiblePTTs)(options).map(
      (ptt: any) => ptt.ptt_series_id,
    )

    const noPTT = statePttSeries.find((ptt: any) => ptt.name == "No PTT")

    if (noPTT && !noPTT.disabled) {
      filteredSeriesIds.push(noPTT.id)
    }

    const result = statePttSeries.map((item: any) => {
      if (filteredSeriesIds.includes(item.id)) {
        return item
      } else {
        return { ...item, disabled: true }
      }
    })

    return result
  }

  const onPttSeriesSelect = (pttSeriesId: number) => {
    selectPttSeries(pttSeriesId)

    onNextStep(pttSeriesId)
  }

  const onPreviousStep = () => {
    push(
      previousStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
    getFooterArray(cart).slice(0, -1)
  }

  // this function will navigate us to the next page without selecting a headset
  const skipCurrentStep = () => {
    push(
      nextStepUrl(currentPage, cartIds, stepSkippers, isTestMode) +
        location.search,
    )
  }

  // We pass here the pttSeriesId because it has not yet updated in the global state.
  // TODO: Refactor this to use the global state instead of passing it as an argument.
  const onNextStep = (pttSeriesId: number) => {
    push(
      nextStepUrl(
        currentPage,
        updateStateWithNewId(cartIds, currentPage, pttSeriesId),
        stepSkippers,
        isTestMode,
      ) + location.search,
    )
  }

  const onPortTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const port_type = isEmpty(value) ? "" : value

    setPTTFilter({ port_type })
  }

  const onMicrophonePreamplifierChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = e.target
    const microphone_preamplifier = isEmpty(value) ? "" : value == "true"

    setPTTFilter({ microphone_preamplifier })
  }

  const onPlugTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const plug_type = isEmpty(value) ? "" : value

    setPTTFilter({ plug_type })
  }

  const filteredPttsSeries = filterPttSeries(pttFiltering)

  const pttSeriesWithSettings = filteredPttsSeries.map((serie: any) => {
    serie.plug_type = []
    serie.microphone_preamplifier = []
    serie.port_type = []

    const compatiblePTTs = isTestMode ? ptts : filterCompatiblePTTs(ptts)

    const filteredPtts = compatiblePTTs.filter(
      (ptt: any) => ptt.ptt_series_id == serie.id,
    )

    for (let ptt of filteredPtts) {
      if (ptt.plug_type != null && !serie.plug_type.includes(ptt.plug_type)) {
        serie.plug_type.push(ptt.plug_type)
      }
      if (
        ptt.microphone_preamplifier != null &&
        !serie.microphone_preamplifier.includes(ptt.microphone_preamplifier)
      ) {
        serie.microphone_preamplifier.push(ptt.microphone_preamplifier)
      }

      if (ptt.port_type != null && !serie.port_type.includes(ptt.port_type)) {
        serie.port_type.push(ptt.port_type)
      }
    }
    return serie
  })

  return (
    <>
      <Header />
      <div className="font-sans flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
        <ProgressBar
          activeStep={3}
          numberOfSteps={9}
          title="Choose PTT series"
        />

        <section className="mt-5 lg:mt-10 mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 mb-10">
            <PortTypeSelect
              onChange={onPortTypeChange}
              selected={prop("port_type", pttFiltering)}
              wrapperClass=""
              tooltip={portTypeTooltip}
              className="tooltip-selectPage"
            />
            <MicrophonePreamplifierSelect
              onChange={onMicrophonePreamplifierChange}
              selected={prop("microphone_preamplifier", pttFiltering)}
              wrapperClass=""
              tooltip={microphonePreamplifierTooltip}
              className="tooltip-selectPage"
            />
            <PlugTypeSelect
              onChange={onPlugTypeChange}
              selected={prop("plug_type", pttFiltering)}
              wrapperClass=""
              tooltip={plugTypeTooltip}
              className="tooltip-selectPage"
            />
          </div>
          <PttList
            list={pttSeriesWithSettings}
            onSelect={onPttSeriesSelect}
            series={true}
          />
        </section>
      </div>
      <ProgressFooter
        backStep={onPreviousStep}
        skipStep={skipCurrentStep}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    pttSeries: state.pttSeries.items,
    ptts: state.ptts.items,
    cart: getCart(state),
    cartIds: state.cartIds,
    stepSkippers: state.stepSkippers.items,
    incompatibilities: state.incompatibilities.items,
    pttFiltering: state.ptts.filtering,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchPttSeries, fetchPtts, selectPttSeries, push, setPTTFilter },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(PttSeriesPage)
