import React from "react"
import { createRoot } from "react-dom/client"

import { Provider } from "react-redux"
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux"
import thunkMiddleware from "redux-thunk"

import { createBrowserHistory } from "history"
import { Route } from "react-router"
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter,
} from "connected-react-router"

import HeadsetSeriesPage from "../pages/HeadsetSeriesPage"
import HeadsetsPage from "../pages/HeadsetsPage"
import PttSeriesPage from "../pages/PttSeriesPage"
import PttsPage from "../pages/PttsPage"
import BottomCablesPage from "../pages/BottomCablesPage"
import PlugConfigurationPage from "../pages/PlugConfigurationPage"
import YourHeadsetPage from "../pages/YourHeadsetPage"
import CableWithoutPttPage from "../pages/CableWithoutPttPage"
import ThankYouPage from "../pages/ThankYouPage"

import { persistStore, persistCombineReducers } from "redux-persist"
import { PersistGate } from "redux-persist/es/integration/react"
import storage from "redux-persist/es/storage"

import * as reducers from "../reducers"
import {
  fetchStepSkippers,
  fetchIncompatibilities,
  resetCart,
} from "../actions"
import { polyfill } from "es6-promise"

polyfill()
const history = createBrowserHistory()
const middleware = routerMiddleware(history)

const setNewExpireCacheDate = () => {
  const expireCacheDate = new Date()
  expireCacheDate.setHours(expireCacheDate.getHours() + 1)

  window.localStorage.setItem("expireCacheDate", expireCacheDate)
}

const mayExpireStore = () => {
  const expireCacheDate = new Date(
    window.localStorage.getItem("expireCacheDate"),
  )
  if (expireCacheDate < new Date()) {
    store.dispatch(resetCart())
  }
  setNewExpireCacheDate()
}

const storageConfig = {
  key: "headsetBuilder",
  storage: storage,
}

const persistedReducers = persistCombineReducers(storageConfig, {
  ...reducers,
  router: connectRouter(history),
})

const store = createStore(
  persistedReducers,
  compose(applyMiddleware(middleware, thunkMiddleware)),
)

const persistor = persistStore(store, undefined, mayExpireStore)

store.dispatch(fetchStepSkippers())
store.dispatch(fetchIncompatibilities())

const element = document.createElement("div")
element.className = "h-100"
document.body.appendChild(element)

const root = createRoot(element)

root.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="h-100">
          <Route exact path="/" component={HeadsetSeriesPage} />
          <Route path="/headsetSeries" component={HeadsetSeriesPage} />
          <Route path="/headsets" component={HeadsetsPage} />
          <Route path="/pttSeries" component={PttSeriesPage} />
          <Route path="/cableWithoutPtts" component={CableWithoutPttPage} />
          <Route path="/ptts" component={PttsPage} />
          <Route path="/bottomCables" component={BottomCablesPage} />
          <Route path="/plugConfiguration" component={PlugConfigurationPage} />
          <Route path="/yourHeadset" component={YourHeadsetPage} />
          <Route path="/thankYou" component={ThankYouPage} />
        </div>
      </ConnectedRouter>
    </Provider>
  </PersistGate>,
)
