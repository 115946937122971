export const microphoneTooltip: string =
  "Your voice switch system determines if you need a dynamic or electret microphone. " +
  "Dynamic microphones have lower output than electret, but electret microphones require external power."

export const headsetTooltip: string =
  "Choose single-sided headset if you only require audio in one ear, or want to follow communication " +
  "in the control center/office.  Choose dual-sided headset if two channels of audio is required. i.e. " +
  "radio audio in one ear and telephone audio in the other ear, or if you want to focus on the headset " +
  "communication, and don’t want to be distracted"

export const activegardTooltip: string =
  "ActiveGard ™ protects your hearing against sudden loud sound bursts. It is built into the headset. " +
  "Some voice switches have a similar function built into the system. ActiveGard may be required to conform " +
  "to regulations set by the FFA and EC directive."

export const portTypeTooltip: string =
  "Depending on your voice switch and configuration, choose analog or USB interface."

export const microphonePreamplifierTooltip: string =
  "Our PTTs are available with a microphone pre-amplifier. This is necessary for our SC 200 and SC 600 series headset."

export const plugTypeTooltip: string =
  "The headset can be hardwired directly into the PTT. Alternatively, it can be supplied with mini-XLR plug, which simply plugs into the appropriate PTT for headset changeability."

export const topCableTooltip: string =
  "The Top Cable connects the headset to the PTT."
