import React, { Component } from "react"
import {
  fetchHeadsetSeries,
  selectHeadsetSeries,
  fetchHeadsets,
  setHeadsetFilter,
} from "../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import { nextStepUrl } from "../helpers/StepHelper"
import {
  getIncompatibleHeadsetSeries,
  filterCompatibleHeadsets,
} from "../helpers/IncompatibilityHelper"
import { map, contains, isEmpty, prop } from "ramda"
import { filterHeadsets } from "../helpers/FilterHelper"

import StepHeader from "../components/StepHeader"
import { ColumnList } from "../lists/ColumnList"
import {
  ActiveGardSelect,
  WearingStyleSelect,
  MicrophoneTypeSelect,
} from "./headsetSeries/Components"
import Header from "../components/Header"
import ProgressBar from "../components/ProgressBar"
import {
  activegardTooltip,
  headsetTooltip,
  microphoneTooltip,
} from "../utils/const"
import {
  ElementType,
  HeadsetSeriesType,
  HeadsetSeriesWithSettingsType,
  StepType,
} from "../utils/types"
import { updateStateWithNewId } from "../reducers"

type HeadsetSeriesPageProps = {
  fetchHeadsetSeries: () => void
  fetchHeadsets: () => void
  location: {
    search: string
  }
  headsetFiltering: () => void
  setHeadsetFilter: (filtering: any) => void
  selectHeadsetSeries: (id: number) => Promise<void>
  push: (address: string) => void
  cartIds: any
  stepSkippers: StepType[]
  headsets: ElementType[]
}

type HeadsetSeriesPageState = {
  headsetSeriess: HeadsetSeriesType[]
  isTestMode: boolean
}

const disableItem = (item: ElementType) => ({ ...item, disabled: true })
const enableItem = (item: ElementType) => ({ ...item, disabled: false })

const toggleItemEnabled = (enabledIds: number) => (item: ElementType) =>
  contains(item.id, enabledIds) ? enableItem(item) : disableItem(item)

class HeadsetSeriesPage extends Component<
  HeadsetSeriesPageProps,
  HeadsetSeriesPageState
> {
  constructor(props: HeadsetSeriesPageProps) {
    super(props)

    this.state = {
      headsetSeriess: [],
      isTestMode: props.location.search.includes("test=true"),
    }
  }

  componentDidMount() {
    this.props.fetchHeadsetSeries()
    this.props.fetchHeadsets()
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { headsetSeriess } = nextProps
    if (!isEmpty(headsetSeriess)) {
      const incompatibleHeadsetSeriess = map(
        disableItem,
        getIncompatibleHeadsetSeries(nextProps),
      )

      this.setState({
        headsetSeriess: this.compatibleHeadsetSeries(
          headsetSeriess,
          incompatibleHeadsetSeriess,
        ),
      })
    }
  }

  onSeriesSelect = async (id: number) => {
    const { selectHeadsetSeries, push, cartIds, stepSkippers, location } =
      this.props

    await selectHeadsetSeries(id)
    push(
      nextStepUrl(
        "headsetSeries",
        updateStateWithNewId(cartIds, "headsetSeriesId", id),
        stepSkippers,
        this.state.isTestMode,
      ) + location.search,
    )
  }

  compatibleHeadsetSeries = (
    headsetSeriess: HeadsetSeriesType[],
    incompatibleHeadsetSeriess: HeadsetSeriesType[],
  ): HeadsetSeriesType[] => {
    return headsetSeriess.filter(
      (headsetSeries) =>
        !incompatibleHeadsetSeriess.some(
          (incompatibleHeadsetSeries) =>
            incompatibleHeadsetSeries.id === headsetSeries.id,
        ),
    )
  }

  sameActiveGard = (headset: ElementType, active_gard: string | null) =>
    isEmpty(active_gard) ||
    headset.active_gard == "both" ||
    headset.active_gard == active_gard

  filterHeadsetSeriess = (options: any) => {
    const compatibleHeadsets = this.state.isTestMode
      ? this.props.headsets
      : filterCompatibleHeadsets(this.props)
    const filteredHeadsets = filterHeadsets(compatibleHeadsets)(options)

    const seriesIds = filteredHeadsets.map(
      (headset: ElementType) => headset.headset_series_id,
    )

    return map(toggleItemEnabled(seriesIds), this.state.headsetSeriess)
  }

  onActiveGardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const active_gard = isEmpty(value) ? "" : value

    this.props.setHeadsetFilter({ active_gard })
  }

  onWearingStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const wearing_style = isEmpty(value) ? "" : value

    this.props.setHeadsetFilter({ wearing_style })
  }

  onMicrophoneTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const microphone_type = isEmpty(value) ? "" : value

    this.props.setHeadsetFilter({ microphone_type })
  }

  transformActiveGuardSeries = (active_gard: string) => {
    if (active_gard === "only_with") return "With ActiveGard™"
    if (active_gard === "only_without") return "Without ActiveGard™"
    return "Both"
  }

  render() {
    const { headsetFiltering, headsets } = this.props
    const headsetSeriess = this.filterHeadsetSeriess(headsetFiltering)

    const seriesWithSettings = headsetSeriess.map(
      (serie: HeadsetSeriesWithSettingsType) => {
        serie.microphone_type = []
        serie.active_gard = []
        serie.wearing_style = []

        const filteredHeadsets = headsets.filter((headset: ElementType) => {
          return headset.headset_series_id == serie.id
        })

        for (let filteredHeadset of filteredHeadsets) {
          if (
            filteredHeadset.microphone_type != null &&
            !serie.microphone_type.includes(filteredHeadset.microphone_type)
          ) {
            serie.microphone_type.push(filteredHeadset.microphone_type)
          }
          if (
            filteredHeadset.active_gard != null &&
            !serie.active_gard.includes(
              this.transformActiveGuardSeries(filteredHeadset.active_gard),
            )
          ) {
            serie.active_gard.push(
              this.transformActiveGuardSeries(filteredHeadset.active_gard),
            )
          }
          if (
            filteredHeadset.wearing_style != null &&
            !serie.wearing_style.includes(filteredHeadset.wearing_style)
          ) {
            serie.wearing_style.push(filteredHeadset.wearing_style)
          }
        }

        return serie
      },
    )

    return (
      <>
        <Header />
        <div className="flex flex-col px-3 md:px-10 lg:px-20 xl:px-40 2xl:px-48">
          <ProgressBar
            activeStep={1}
            numberOfSteps={9}
            title="Choose headset"
          />
          <StepHeader text="Start your customization" />
          <section className="mt-5 lg:mt-10 mb-20">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 mb-10">
              <MicrophoneTypeSelect
                onChange={this.onMicrophoneTypeChange}
                selected={prop("microphone_type", headsetFiltering)}
                wrapperClass=""
                tooltip={microphoneTooltip}
                className="tooltip-selectPage"
              />
              <WearingStyleSelect
                onChange={this.onWearingStyleChange}
                selected={prop("wearing_style", headsetFiltering)}
                wrapperClass=""
                tooltip={headsetTooltip}
                className="tooltip-selectPage"
              />
              <ActiveGardSelect
                onChange={this.onActiveGardChange}
                selected={prop("active_gard", headsetFiltering)}
                wrapperClass=""
                tooltip={activegardTooltip}
                className="tooltip-selectPage"
              />
            </div>
            <ColumnList
              list={seriesWithSettings}
              onSelect={this.onSeriesSelect}
              series={true}
            />
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    headsetSeriess: state.headsetSeries.items,
    cartIds: state.cartIds,
    stepSkippers: state.stepSkippers.items,
    incompatibilities: state.incompatibilities.items,
    headsets: state.headsets.items,
    headsetFiltering: state.headsets.filtering,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchHeadsetSeries,
      selectHeadsetSeries,
      push,
      fetchHeadsets,
      setHeadsetFilter,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(HeadsetSeriesPage)
